.modal__container {
    position: fixed;
    inset: 0;
    background-color: rgba(51, 51, 51, 0.3);
    backdrop-filter: blur(1px);
    opacity: 0;
    transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal__portal {}

.modal__container--active {
    transition-duration: 250ms;
    transition-delay: 0ms;
    opacity: 1;
}

.modal__container--active .modal__content {
    transform: translateY(0);
    opacity: 1;
    transition-delay: 150ms;
    transition-duration: 350ms;
}

.modal__content {
    transform: translateY(100px);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 12px;
    opacity: 0;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    min-height: 50px;
    min-width: 50px;
    max-height: 80%;
    max-width: 80%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: white;
    overflow: scroll;
}